<template>

  <div class="row" ref="academyForm">

    <div class="col-xs-12 col-sm-12 col-md-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>

          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <ValidationProvider
                    vid="name"
                    rules="required"
                    name="The title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Title"
                              name="title"
                              fou
                              @keyup="generateSomeFields"
                              v-model="formData.title">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <ValidationProvider
                          vid="name"
                          rules="required"
                          name="url"
                          v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="URL"
                              name="url"
                              fou
                              v-model="formData.url">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="color"
                  rules=""
                  name="color"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Color"
                            name="color"
                            fou
                            v-model="formData.color">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Description</label>
                  <editor
                    v-model="formData.description"
                    :api-key="editorKey"
                    :init='{
                    height: 400,
                    paste_data_images: true,
                    plugins: [
                    "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                     "searchreplace wordcount visualblocks visualchars code fullscreen",
                    "insertdatetime media nonbreaking save table contextmenu directionality",
                    "emoticons template paste textcolor colorpicker textpattern"
                    ],
                    toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    toolbar2: "print preview media | forecolor backcolor emoticons",
                    image_advtab: true,
                  }'
                  />
                </div>

              </div>
              <div class="col-md-12">
                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

            <l-button @click="$router.push('/academies/list')" type="danger" wide>Cancel</l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";

extend("required", {
  message: "{_field_} is required"
});


export default {
  components: {
      FormGroupTextArea,
      [Option.name]: Option,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      FgSelect,
      LSwitch,
      'editor': Editor
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        title: "",
        description: "",
        'url': "",
        is_active: true,
        color: '',
      },

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.academyForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Academy";
      this.getAcademy();
    } else {
      this.editMode = false;
      this.formTitle = "Add Academy";
      this.loader.hide();
    }
  },


  methods: {

    async getAcademy() {
      try {
        let response = await this.axios.get("academies/get/" + this.id);
        this.formData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Academy Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
        this.loader.hide();
      }
    },

    async submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("academies/update/" + this.id, this.formData);
        successMessage = "Academy Updated Successfully";
      } else {
        request = this.axios.post("academies/create", this.formData);
        successMessage = "Academy Added Successfully";
      }

      try {
        await request;
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/academies/list");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      } finally {
        this.submitting = false;
      }
    },
    generateSomeFields() {
      let newUrl = '';
      if (this.formData.title) {
        newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "_");
      }
      this.formData.url = newUrl;
    },
  }
}
</script>

<style>
</style>
