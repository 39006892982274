<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Academy List</h4>
          <router-link v-if="$store.getters['auth/checkAccess']('academies/create')"
                       class="btn btn-info btn-wd"
                       :to="'/academies/create'">
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

        <div>
          <general-data-table
                  ref="table"
                  :method="'POST'"
                  :row-key="'id'"
                  @reorder="reorder($event)"
                  :api-url="'academies/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
                    :min-width="120"
                    fixed="right"
                    align="center"
                    label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/checkAccess']('academies/change-active-status')"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
                    :min-width="120"
                    fixed="right"
                    align="center"
                    label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/checkAccess']('academies/update')" v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/academies/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i></router-link>

                <a v-if="$store.getters['auth/checkAccess']('academies/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>

          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this academy?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {Switch as LSwitch} from '@/components'


export default {
  components: {
    NLPagination,
    DeleteModal,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    LSwitch,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
        {label: 'URL', value: 'url', minWidth: '200', align: 'center'},
      ],

      deleteModalVisibility: false,
      toDeleteId: undefined
    }

  },
    async mounted() {
        this.$refs.table.createDragAndDropSorting();
    },

  methods: {
      handleActiveStatus(row) {
          let data = {
              'is_active': row.is_active
          }
          this.axios.post("academies/change-active-status/" + row.id, data).then((response) => {
              this.$notify({
                  message: "Academy updated successfully",
                  timeout: 1000,
                  type: 'success'
              });
          }).catch((error) => {
              console.log(error)
          })
      },
      async reorder(data) {
          try {
              let body = [];
              data.forEach((row, i) => {
                  body.push({"id": row.id, "sort_order": i + 1})
              });

              await this.axios.post("academies/re-order", body);

              this.$notify({
                  message: "order updated successfully",
                  timeout: 1000,
                  type: 'success'
              });

          } catch (error) {
              this.$notify({
                  message: error.response.data.message,
                  timeout: 2000,
                  type: 'danger'
              })
          }
      },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      this.axios.delete("academies/delete/" + this.toDeleteId).then((response) => {

        this.$notify({
          message: "Academy deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    }
  },
}
</script>
<style>
</style>
